var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pathModul",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{class:_vm.$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1',attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-select',{attrs:{"items":_vm.itemStation,"label":"Station","prepend-inner-icon":"mdi-store-marker","hide-details":"","solo":"","dense":""},on:{"change":_vm.selectValueStation}})],1),_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp? '' : '12'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-inner-icon":"mdi-calendar-month","readonly":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"change":_vm.selectDate,"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"white--text btn-generate button mr-2 mt-3",attrs:{"solo":"","dense":"","normal":"","disabled":_vm.valStation==null},on:{"click":_vm.GenerateTable}},[_vm._v(" GENERATE REPORT ")]),_c('v-btn',{staticClass:"white--text btn-upload button mt-3",attrs:{"solo":"","dense":"","normal":""},on:{"click":_vm.UploadForm}},[_vm._v(" UPLOAD FILE ")])],1)],1),_c('v-card',{staticClass:"pa-4",staticStyle:{"max-height":"calc(100vh - 230px)","overflow-y":"auto"}},[_c('v-subheader',{staticClass:"subTitle black--text font-weight-bold"},[_vm._v(" Maintenance Record For "+_vm._s(_vm.stationID)+" ("+_vm._s(_vm.dateSelected)+") ")]),_c('v-data-table',{staticClass:"elevation-1 tableMaintenance headerDtSarawak",attrs:{"headers":_vm.thead_maintenance,"items":_vm.tbody_maintenance,"header-props":{ sortIcon: null },"items-per-page":5,"no-select-on-click":"","custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.datetimeUpload",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.datetimeUpload)}})]}},{key:"item.fileDoc",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"#02b2c3"},domProps:{"innerHTML":_vm._s(item.fileDoc)},on:{"click":function($event){return _vm.openFileDoc(item)}}})]}},{key:"item.delDetails",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.userCanUse,"color":"red"},domProps:{"innerHTML":_vm._s(item.delDetails)},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)})],1)],1),_c('v-container',{staticClass:"container-upload-file form-uploadFile"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }